<template>
    <v-sheet min-height="100" class="fill-height" color="transparent">
        <v-lazy
            :v-model="user"
            :options="{
                threshold: 0.5,
            }"
            class="fill-height"
        >
            <v-list-item
                color="primary"
                @click="hide"
                three-line
                link
                :to="{
                    name: 'user',
                    params: { id: user.id, user },
                }"
            >
                <v-list-item-avatar size="44" class="my-auto">
                    <v-img
                        v-if="profilePicURL"
                        :src="profilePicURL"
                        :alt="user.name"
                    />
                    <v-icon size="48" v-else>mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="mt-3">{{
                        user.name
                    }}</v-list-item-title>
                    <v-list-item class="pa-0">
                        <v-list-item-icon class="mt-2 mb-0">
                            <v-icon>mdi-account-tie</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle class="mt-0">{{
                            user.role
                        }}</v-list-item-subtitle>
                    </v-list-item>
                </v-list-item-content>
            </v-list-item>
        </v-lazy>
    </v-sheet>
</template>
<script>
import { storage } from '@/services/firebase'

export default {
    name: 'UserCard',
    props: {
        user: {
            type: Object,
            default() {
                return {
                    name: '',
                    email: '',
                    phone: '',
                    role: '',
                    permissions: '',
                    language: '',
                }
            },
        },
    },
    data() {
        return {
            folderPictures: 'users_pictures',
            profilePicURL: null,
            userDialog: false,
        }
    },
    async created() {
        if (this.user.picture && !this.profilePicURL) {
            const companyId = JSON.parse(localStorage.getItem('company'))
            const path = `${companyId}/${this.folderPictures}`
            const storageRef = storage().ref(path)
            const profilePicRef = storageRef.child(this.user.picture)
            await profilePicRef
                .getDownloadURL()
                .then(URL => {
                    this.user.profilePicURL = URL
                    this.profilePicURL = URL
                })
                .catch(error => {
                    this.profilePicURL = null
                    error && true
                })
        }
    },
    mounted() {},
    methods: {
        hide() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('userDetail')
            }
        },
    },
}
</script>
