<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn
                class="mr-3"
                depressed
                color="primary"
                icon
                rounded
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            {{ title }}
            <v-spacer></v-spacer>
            <v-btn
                v-if="!createForm"
                depressed
                color="primary"
                rounded
                icon
                class=""
                @click="update"
            >
                <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn
                v-if="!createForm && !cardProfile"
                depressed
                rounded
                color="primary"
                @click="openDeleteDialog"
                icon
            >
                <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-dialog
                :retain-focus="false"
                v-model="deleteDialog"
                persistent
                max-width="400px"
            >
                <v-card :loading="loading">
                    <v-card-title class="text-h5">Delete User</v-card-title>
                    <v-card-text>
                        Are you sure you want to delete this user?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text
                            color="secondary"
                            @click="deleteDialog = false"
                        >
                            Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="deleteUser"
                            text
                            color="error"
                            :loading="loading"
                        >
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                :disabled="!createForm && !isEditing"
            >
                <v-row justify="center">
                    <input
                        v-if="createForm || isEditing"
                        ref="profilePic"
                        name="profilePic"
                        accept="image/*"
                        type="file"
                        class="d-none"
                        @change="onFilePicked"
                    />
                    <v-hover v-slot:default="{ hover }">
                        <v-avatar
                            color="primary"
                            size="96"
                            class="avatar"
                            @click="$refs.profilePic.click()"
                        >
                            <v-img v-if="profilePicURL" :src="profilePicURL">
                                <v-fade-transition origin="center">
                                    <div
                                        v-if="hover"
                                        class="change-picture dark"
                                    >
                                        <v-icon color="white"
                                            >mdi-camera</v-icon
                                        >
                                    </div>
                                </v-fade-transition>
                            </v-img>
                            <v-icon
                                v-else
                                :size="!hover ? 96 : null"
                                color="white"
                            >
                                {{
                                    hover ? 'mdi-camera' : 'mdi-account-circle'
                                }}
                            </v-icon>
                        </v-avatar>
                    </v-hover>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-model="user.name"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required]"
                            prepend-icon="mdi-account-tie"
                            label="Name *"
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="user.email"
                            :readonly="!createForm && !isEditing"
                            :rules="[rules.required, rules.email]"
                            prepend-icon="mdi-email"
                            label="Email *"
                            required
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <vue-tel-input
                            v-model="user.phone"
                            mode="international"
                            :inputOptions="inputOptions1"
                            :disabled="!createForm && !isEditing"
                            validCharactersOnly
                        />
                    </v-col>
                    <v-col cols="6">
                        <vue-tel-input
                            v-model="user.phone2"
                            mode="international"
                            :inputOptions="inputOptions2"
                            :disabled="!createForm && !isEditing"
                            validCharactersOnly
                        ></vue-tel-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            v-if="!createForm && !isEditing"
                            v-model="user.language"
                            :readonly="!createForm && !isEditing"
                            prepend-icon="mdi-account-tie-voice"
                            label="Language *"
                            required
                        />
                        <v-select
                            v-if="createForm || isEditing"
                            :items="itemsLanguage"
                            v-model="user.language"
                            :rules="[rules.required]"
                            prepend-icon="mdi-account-tie-voice"
                            label="Language *"
                            required
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-if="!createForm && !isEditing"
                            :readonly="!createForm && !isEditing"
                            v-model="user.role"
                            :rules="[rules.required]"
                            prepend-icon="mdi-cog"
                            label="Role "
                            required
                        />
                        <v-autocomplete
                            v-if="createForm || isEditing"
                            v-model="user.role"
                            :items="companyRoles"
                            @change="selectPermissions"
                            item-text="name"
                            :rules="[rules.required]"
                            prepend-icon="mdi-cog"
                            label="Role *"
                            required
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-autocomplete
                            v-if="createForm"
                            v-model="permissionsRole"
                            :items="usersPermissions"
                            :disabled="!createForm && !isEditing"
                            prepend-icon="mdi-clipboard-check"
                            chips
                            attach
                            :rules="[rules.required]"
                            label="Permissions *"
                            multiple
                            required
                            :menu-props="{ top: true, offsetY: true }"
                        ></v-autocomplete>
                        <v-autocomplete
                            v-if="!createForm && !cardProfile"
                            v-model="user.permissions"
                            deletable-chips
                            :items="usersPermissions"
                            :disabled="!createForm && !isEditing"
                            prepend-icon="mdi-clipboard-check"
                            chips
                            attach
                            :rules="[rules.required]"
                            label="Permissions *"
                            multiple
                            required
                            :menu-props="{ top: true, offsetY: true }"
                        ></v-autocomplete>
                    </v-col>
                    <v-row no-gutters class="px-3 mt-5">
                        <v-col cols="12" xl="6" lg="6" md="6">
                            <v-combobox
                                v-model="user.process"
                                prepend-icon="mdi-hammer-wrench"
                                label="Process"
                                :items="processes"
                                item-text="name"
                                class="pa-0 ma-0"
                                :disabled="disableOperatorFields()"
                            >
                            </v-combobox>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" md="6" class="pl-3">
                            <v-text-field
                                v-model="signCode"
                                prepend-icon="mdi-barcode"
                                label="Sign Code "
                                class="pa-0 ma-0"
                                :disabled="disableOperatorFields()"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="px-6 mb-4">
                        <v-switch
                            v-model="user.operatorRole"
                            label="Set Basic Operator View"
                            hide-details
                            :input-value="true"
                            class="my-2"
                            :disabled="
                                disableOperatorFields() ||
                                    this.$router.currentRoute.name ==
                                        'operators'
                            "
                            @change="setOperatorPermissions"
                        ></v-switch>
                    </v-row>
                </v-row>
            </v-form>
            <small v-if="createForm">* indicates required field</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                v-if="createForm"
                text
                color="primary"
                :loading="loading"
                :disabled="!valid"
                @click="saveUser"
            >
                Save
            </v-btn>
            <v-btn
                v-if="isEditing"
                text
                color="primary"
                :loading="loading"
                @click="saveUser"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import API from '@/services/api'
import { auth, storage } from '@/services/firebase'
import _ from 'lodash'
import cryptoJs from 'crypto-js'

export default {
    name: 'UserForm',
    props: {
        title: {
            type: String,
            required: true,
        },
        user: {
            type: Object,
            default() {
                return {
                    name: '',
                    email: '',
                    phone: '',
                    role: '',
                    permissions: '',
                    language: '',
                }
            },
        },
        image: {
            type: String,
            default() {
                return ''
            },
        },
        createForm: {
            type: Boolean,
            required: true,
        },
        cardProfile: Boolean,
        originalUser: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            company: JSON.parse(localStorage.getItem('company')),
            folder: 'users_pictures',
            itemsLanguage: ['English', 'Spanish'],
            loading: false,
            error: false,
            loadingPicture: false,
            permissionsPerRole: [],
            permissionsRole: null,
            inputOptions1: {
                placeholder: 'Phone 1',
            },
            inputOptions2: {
                placeholder: 'Phone 2',
            },
            settingsRoles: {},
            isEditing: false,
            profilePic: null,
            documents: [],
            errorMsg: null,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid E-mail',
                number: () => this.validNumber || 'Not a valid number',
            },
            deleteDialog: false,
            deleteError: false,
            profilePicURL: '',
            processes: [],
            currentuser: {},
            signCode: undefined,
        }
    },
    computed: {
        userDiff: function() {
            if (!this.createForm) {
                return this.objDiff(this.originalUser, this.user)
            } else {
                return null
            }
        },
        ...mapState(['companyRoles', 'usersPermissions', 'settings']),
    },
    watch: {
        permissionsPerRole: function(newObject) {
            let permissions = []
            Object.keys(newObject.permissions).map(key => {
                for (let item of newObject.permissions[key]) {
                    permissions.push(item.permission)
                }
            })
            this.permissionsRole = permissions
            this.user.permissions = _.cloneDeep(this.permissionsRole)
        },
    },
    created() {
        const settingsRoles = this.settings.filter(x => x.name == 'Permissions')
        this.settingsRoles = settingsRoles[0]
        if (!this.createForm) {
            this.phone = _.cloneDeep(this.user.phone)
            this.phone2 = _.cloneDeep(this.user.phone2)
        }
    },
    async mounted() {
        this.profilePicURL = this.image
        const {
            data: { settings },
        } = await API.getSettings()
        this.processes = settings.find(s => s.name == 'Processes').processes
        const {
            data: { user },
        } = await API.getMyInfo()
        this.currentuser = user
        if (this.$router.currentRoute.name == 'operators') {
            this.user.operatorRole = true
            this.setOperatorPermissions()
        }
        if (this.user && this.user.signCode) {
            if (
                this.currentuser.permissions.includes('editOperators') ||
                this.currentuser.id == this.user.id
            ) {
                const bytes = cryptoJs.AES.decrypt(
                    this.user.signCode,
                    process.env.VUE_APP_ENCRYPTION_PHRASE
                )
                this.signCode = bytes.toString(cryptoJs.enc.Utf8)
            } else {
                this.signCode = '******'
            }
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onFilePicked: function(event) {
            this.user.profilePicURL = URL.createObjectURL(event.target.files[0])
            this.user.picture =
                this.user.profilePicURL.split('/').pop() + '.jpg'
            this.profilePicURL = this.user.profilePicURL
        },

        selectPermissions: function(event) {
            const permissionsPerRole = this.settingsRoles.roles.filter(
                x => x.name == event
            )
            this.permissionsPerRole = permissionsPerRole[0]
        },

        objDiff: function(origObj, newObj) {
            function changes(newObj, origObj) {
                let arrayIndexCounter = 0

                return _.transform(newObj, function(result, value, key) {
                    if (!_.isEqual(value, origObj[key])) {
                        const resultKey = _.isArray(origObj)
                            ? arrayIndexCounter++
                            : key
                        result[resultKey] =
                            _.isObject(value) && _.isObject(origObj[key])
                                ? changes(value, origObj[key])
                                : value
                    }
                })
            }
            return changes(newObj, origObj)
        },
        loadImage: function(src) {
            return new Promise(resolve => {
                const img = new Image()
                img.src = src
                img.onload = () => resolve(img)
            })
        },
        toBlob: function(canvas) {
            return new Promise(resolve => {
                canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.8)
            })
        },
        update: function() {
            this.isEditing = !this.isEditing
        },
        resizeImage: async function(img) {
            const canvas = document.createElement('canvas')
            let { height, width } = img
            const MAX = 250
            if (height > width) {
                if (height > MAX) {
                    width *= MAX / height
                    height = MAX
                }
            } else {
                if (width > MAX) {
                    height *= MAX / width
                    width = MAX
                }
            }
            canvas.height = height
            canvas.width = width
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0, width, height)
            const blob = await this.toBlob(ctx.canvas)
            const name = img.src.split('/').pop() + '.jpg'
            const file = new File([blob], name, { type: 'image/jpeg' })
            return file
        },
        uploadImage: async function(file) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const profilePicRef = storageRef.child(file.name)
            const result = await profilePicRef.put(file)
            return result
        },
        closeDialog: function() {
            this.$emit('closeDialog')
            this.$refs.form.reset()
            this.error = false
            this.user.phone = ''
            this.user.phone2 = ''
            this.errorMsg = null
            this.isEditing = false
        },
        deleteImage: async function(img) {
            const path = `${this.company}/${this.folder}`
            const storageRef = storage().ref(path)
            const refOldPictture = storageRef.child(img)
            try {
                await refOldPictture.delete().catch(error => {
                    console.log(error)
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
            return true
        },
        deleteDocument: async function(doc) {
            const path = `${this.company}/users_documents/${this.user.id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(doc)
            await refDoc.delete().catch(error => {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            })
            return true
        },

        saveUser: function() {
            if (this.createForm) {
                this.createUser()
            } else {
                this.updateUser()
            }
        },
        openDeleteDialog: function() {
            this.deleteDialog = true
        },
        updateUser: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.user
                if (this.userDiff.permissions) {
                    this.userDiff.permissions = this.user.permissions
                }
                if (this.userDiff.profilePicURL) {
                    const img = await this.loadImage(this.user.profilePicURL)
                    const imgResize = await this.resizeImage(img)
                    if (this.originalUser.picture) {
                        await this.deleteImage(this.originalUser.picture)
                    }
                    await this.uploadImage(imgResize)
                }
                delete this.userDiff.profilePicURL
                if (this.userDiff.process) {
                    this.userDiff.processData = this.userDiff.process
                    delete this.userDiff.process
                }

                if (this.currentuser.permissions.includes('editOperators')) {
                    let prevDecryptedCode = undefined
                    if (this.user.signCode) {
                        const bytes = cryptoJs.AES.decrypt(
                            this.user.signCode,
                            process.env.VUE_APP_ENCRYPTION_PHRASE
                        )
                        prevDecryptedCode = bytes.toString(cryptoJs.enc.Utf8)
                    }

                    if (
                        (prevDecryptedCode &&
                            prevDecryptedCode != this.signCode) ||
                        !this.user.signCode
                    ) {
                        let encryptedCode
                        if (this.signCode) {
                            encryptedCode = cryptoJs.AES.encrypt(
                                this.signCode,
                                process.env.VUE_APP_ENCRYPTION_PHRASE
                            ).toString()
                        } else {
                            encryptedCode = null
                        }
                        this.userDiff.signCode = encryptedCode
                    }
                }
                const {
                    data: { user },
                } = await API.updateUser(id, this.userDiff)
                if (user.picture) {
                    user.profilePicURL = this.user.profilePicURL
                }
                user.companyRoles = this.user.companyRoles
                user.usersPermissions = this.user.usersPermissions
                this.$emit('replaceUser', id, user)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        createUser: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (this.user.profilePicURL) {
                    const img = await this.loadImage(this.user.profilePicURL)
                    const imgResize = await this.resizeImage(img)
                    await this.uploadImage(imgResize)
                }
                this.user.permissions = this.permissionsRole
                if (this.user.operatorRole) {
                    this.user.permission = []
                }
                if (this.currentuser.permissions.includes('createOperators')) {
                    if (this.signCode) {
                        const encryptedCode = cryptoJs.AES.encrypt(
                            this.signCode,
                            process.env.VUE_APP_ENCRYPTION_PHRASE
                        ).toString()
                        this.user.signCode = encryptedCode
                    }
                }
                const {
                    data: { user },
                } = await API.createUser(this.user)
                await auth().sendPasswordResetEmail(user.email)
                if (user.picture) {
                    user.profilePicURL = this.user.profilePicURL
                }
                this.$emit('addUser', user)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        deleteUser: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id, picture } = this.user
                await API.deleteUser(id)
                try {
                    if (picture) await this.deleteImage(picture)
                    const {
                        data: { documents },
                    } = await API.getUserDocuments(id)
                    this.documents = documents
                    if (this.documents) {
                        this.documents.forEach(doc => {
                            this.deleteDocument(doc.file)
                        })
                        await API.deleteUserDocuments(id)
                    }
                } catch (error) {
                    console.log(error.message)
                }
                this.$emit('removeUser', id)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        disableOperatorFields() {
            if (this.currentuser.permissions) {
                return (
                    (this.createForm &&
                        !this.currentuser.permissions.includes(
                            'createOperators'
                        )) ||
                    (!this.createForm &&
                        !this.currentuser.permissions.includes('editOperators'))
                )
            } else {
                return true
            }
        },
        setOperatorPermissions() {
            if (this.user.operatorRole) {
                const operatorPermissions = [
                    'readSettings',
                    'readBOM',
                    'readAllBOM',
                    'readClients',
                    'readProjects',
                    'readProjectQuotes',
                    'readAllProjects',
                    'readSuppliers',
                    'readPartNumbers',
                    'readHistory',
                    'readTools',
                    'readUsers',
                    'readQuotes',
                    'readAll',
                    'readAllWO',
                ]
                this.permissionsRole = this.permissionsRole
                    ? this.permissionsRole
                    : []
                operatorPermissions.forEach(permission => {
                    let existingPermission = this.permissionsRole.includes(
                        permission
                    )

                    if (!existingPermission) {
                        this.permissionsRole.push(permission)
                    }
                })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.vue-tel-input {
    border: none;
    border-radius: 0px;
    font-size: 16px;
}
.vue-tel-input.disabled {
    opacity: 0.5;
}

.vue-tel-input:focus-within {
    box-shadow: none;
    border-color: 'primary';
    color: 'primary';
}
</style>
